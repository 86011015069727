import { Injectable } from '@angular/core';
import { AbstractGraphqlRequest, GraphqlRequestType } from '@ckmk/angular';
import { TypedDocumentNode } from '@apollo/client';
import { SolutionModel } from '../model/solution.model';
import { SolutionCreateMutation } from '../../../queries/default/solution.create.mutation';
import { SolutionUpdateMutation } from '../../../queries/default/solution.update.mutation';

@Injectable({
  providedIn: 'root',
})
export class SolutionRequestMutation extends AbstractGraphqlRequest<SolutionModel> {
  protected override type: GraphqlRequestType = 'mutation';

  protected override getQuery(payload: any): TypedDocumentNode<any, any> {
    return payload.data.id ? SolutionUpdateMutation : SolutionCreateMutation;
  }

  protected override onParseResult(data: any, payload?: any) {
    const solutionData = data.createSolution || data.updateSolution;

    if (solutionData) {
      return new SolutionModel(solutionData);
    }

    return null;
  }
}
