import { Injectable } from '@angular/core';
import { AbstractGraphqlRequest, GraphqlRequestType } from '@ckmk/angular';
import { TypedDocumentNode } from '@apollo/client';
import { ServiceModel } from '../model/service.model';
import { ServiceCreateMutation } from '../../../queries/default/service.create.mutation';
import { ServiceUpdateMutation } from '../../../queries/default/service.update.mutation';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestMutation extends AbstractGraphqlRequest<ServiceModel> {
  protected override type: GraphqlRequestType = 'mutation';

  protected override getQuery(payload: any): TypedDocumentNode<any, any> {
    return payload.data.id ? ServiceUpdateMutation : ServiceCreateMutation;
  }

  protected override onParseResult(data: any, payload?: any) {
    const serviceData = data.createService || data.updateService;

    if (serviceData) {
      return new ServiceModel(serviceData);
    }

    return null;
  }
}
