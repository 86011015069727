import { Injectable } from '@angular/core';
import {
  AbstractGraphqlRequest,
  GraphqlRequestType,
  ItemNotFound,
} from '@ckmk/angular';
import { TypedDocumentNode } from '@apollo/client';
import { ServiceModel } from '../model/service.model';
import { ServiceQuery } from '../../../queries/default/service.query';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestQuery extends AbstractGraphqlRequest<
  ServiceModel | ItemNotFound
> {
  protected override type: GraphqlRequestType = 'query';

  protected override getQuery(): TypedDocumentNode<any, any> {
    return ServiceQuery;
  }

  protected override onParseResult(data: any, payload?: any) {
    let item = data.findOneService;

    if (item) {
      return new ServiceModel(item);
    }

    return new ItemNotFound();
  }
}
