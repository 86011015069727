import { Route } from '@angular/router';
import { ServiceListComponent } from '../components/default/service-list/service-list.component';
import { ServiceShowComponent } from '../components/default/service-show/service-show.component';
import { ServiceManageComponent } from '../components/default/service-manage/service-manage.component';
import { ServiceCategoryListComponent } from '../components/category/category-list/service-category-list.component';
import { ServiceCategoryShowComponent } from '../components/category/category-show/service-category-show.component';
import { ServiceCategoryManageComponent } from '../components/category/category-manage/service-category-manage.component';

export default {
  path: 'services',
  children: [
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full',
    },
    {
      path: 'list',
      component: ServiceListComponent,
      data: {
        permissions: ['PERMISSION_READ_SERVICE'],
      },
    },
    {
      path: 'show/:service',
      component: ServiceShowComponent,
      data: {
        permissions: ['PERMISSION_READ_SERVICE'],
      },
    },
    {
      path: 'create',
      component: ServiceManageComponent,
      data: {
        permissions: ['PERMISSION_CREATE_SERVICE'],
      },
    },
    {
      path: 'update/:service',
      component: ServiceManageComponent,
      data: {
        permissions: ['PERMISSION_UPDATE_SERVICE'],
      },
    },
    {
      path: 'copy/:service',
      component: ServiceManageComponent,
      data: {
        permissions: ['PERMISSION_COPY_SERVICE'],
      },
    },
    {
      path: 'categories',
      children: [
        {
          path: '',
          redirectTo: 'list',
          pathMatch: 'full',
        },
        {
          path: 'list',
          component: ServiceCategoryListComponent,
          data: {
            permissions: ['PERMISSION_READ_SERVICE_CATEGORY'],
          },
        },
        {
          path: 'show/:category',
          component: ServiceCategoryShowComponent,
          data: {
            permissions: ['PERMISSION_READ_SERVICE_CATEGORY'],
          },
        },
        {
          path: 'create',
          component: ServiceCategoryManageComponent,
          data: {
            permissions: ['PERMISSION_CREATE_SERVICE_CATEGORY'],
          },
        },
        {
          path: 'update/:category',
          component: ServiceCategoryManageComponent,
          data: {
            permissions: ['PERMISSION_UPDATE_SERVICE_CATEGORY'],
          },
        },
        {
          path: 'copy/:category',
          component: ServiceCategoryManageComponent,
          data: {
            permissions: ['PERMISSION_COPY_SERVICE_CATEGORY'],
          },
        },
        {
          path: '**',
          redirectTo: 'list',
          pathMatch: 'full',
        },
      ],
    },
    {
      path: '**',
      redirectTo: 'list',
      pathMatch: 'full',
    },
  ],
} as Route;
