import { Injectable } from '@angular/core';
import {
  AbstractGraphqlRequest,
  GraphqlRequestType,
  ItemNotFound,
} from '@ckmk/angular';
import { TypedDocumentNode } from '@apollo/client';
import { SolutionModel } from '../model/solution.model';
import { SolutionQuery } from '../../../queries/default/solution.query';

@Injectable({
  providedIn: 'root',
})
export class SolutionRequestQuery extends AbstractGraphqlRequest<
  SolutionModel | ItemNotFound
> {
  protected override type: GraphqlRequestType = 'query';

  protected override getQuery(): TypedDocumentNode<any, any> {
    return SolutionQuery;
  }

  protected override onParseResult(data: any, payload?: any) {
    let item = data.findOneSolution;

    if (item) {
      return new SolutionModel(item);
    }

    return new ItemNotFound();
  }
}
