import {
  FieldTypesConfigList,
  FileAcceptTypesEnum,
  FormBuilderInterface,
  FormTypeInterface,
  PaginationRequest,
} from '@ckmk/angular';
import { Injectable } from '@angular/core';
import { ServiceModel } from '../model/service.model';
import { ServiceService } from '../../../services/service.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceFormType implements FormTypeInterface {
  public fields: FieldTypesConfigList = [];

  public constructor(private readonly _service: ServiceService) {}

  public async configure(
    builder: FormBuilderInterface<ServiceModel>,
  ): Promise<void> {
    const data = builder.getData();

    if (data?.id) {
      builder.add({
        type: 'choice',
        name: 'active',
        label: 'Enable',
        required: false,
        help: 'Enable help',
        config: {
          type: 'checkbox-boolean',
          buttonType: 'button-only',
        },
      });
    }

    builder
      .add({
        type: 'select',
        name: 'category',
        label: 'Category',
        required: true,
        config: {
          enableDefaultOption: false,
          options: async () => {
            return this._service.categoriesQuery
              .fetchQuery({
                payload: {
                  pagination: new PaginationRequest(0),
                },
              })
              .then((res) => res?.result || [])
              .then((cats) =>
                cats.map(
                  (cat) =>
                    ({
                      label: cat.title,
                      value: cat.id,
                      description: cat.shortDescription,
                    }) as any,
                ),
              );
          },
        },
      })
      .add({
        type: 'file',
        name: 'cover',
        label: 'Cover',
        required: true,
        config: {
          accept: FileAcceptTypesEnum.IMAGES,
        },
      })
      .add({
        type: 'file',
        name: 'picture',
        label: 'Picture',
        required: true,
        config: {
          accept: FileAcceptTypesEnum.IMAGES,
        },
      })
      .add({
        type: 'translation',
        name: 'title',
        label: 'Title',
        required: true,
        config: {
          type: 'text',
          key: data?.title?.key,
          domain: data?.title?.domain || 'services',
        },
      })
      .add({
        type: 'translation',
        name: 'description',
        label: 'Description',
        required: true,
        config: {
          type: 'longtext',
          key: data?.description?.key,
          domain: data?.description?.domain || 'services',
        },
      })
      .add({
        type: 'translation',
        name: 'shortDescription',
        label: 'Short description',
        required: true,
        config: {
          type: 'longtext',
          key: data?.shortDescription?.key,
          domain: data?.shortDescription?.domain || 'services',
        },
      });
  }
}
