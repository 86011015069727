<ng-container>
  @defer (when isReady()) {
    <dashboard-header
      [viewName]="'solution-categories'"
      [subTitle]="getSubTitle() | trans: {
        title: categoryItem?.title,
      }"
      [breadcrumb]="breadcrumbItems"
    />

    <form-group
      [name]="'service-category'"
      [type]="formType"
      [data]="categoryItem"
      [errors]="errors"
      [disableButtons]="true"
      (onSubmit)="onSave($event)"
    >
      <dashboard-card>
        <flex-content>
          <form-row [target]="'active'"/>

          <tabs-container
            [items]="tabs"
            [disabled]="loading"
          >
            <tab-content [name]="'infos'">
              <form-row [target]="'cover'"/>

              <form-row [target]="'title'"/>
            </tab-content>

            <tab-content [name]="'shortDescription'">
              <form-row [target]="'shortDescription'"/>
            </tab-content>

            <tab-content [name]="'description'">
              <form-row [target]="'description'"/>
            </tab-content>

            <tab-content [name]="'cardStyle'">
              <flex-content
                [direction]="'row'"
                [tabletColumns]="2"
                [computerColumns]="2"
              >
                <flex-content>
                  <form-row [target]="'data'"/>
                </flex-content>

                <flex-content>
                  ici se trouvera un aperçu du dégradé !
                </flex-content>
              </flex-content>
            </tab-content>
          </tabs-container>


          <div class="form-buttons">
            <form-button
              [label]="'Save' | trans"
              [icon]="icons.save"
            />
          </div>
        </flex-content>
      </dashboard-card>
    </form-group>
  } @loading (minimum 500) {
    <div>Form loading</div>
  }
</ng-container>
