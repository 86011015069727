import { AbstractData } from '../../../../../../../classes/abstract/data/abstract.data';

export class ServiceCategoryData extends AbstractData {
  protected override onClone(): ServiceCategoryData {
    return new ServiceCategoryData({
      leftColor: this._leftColor,
      rightColor: this._rightColor,
    });
  }
}
