import { Injectable } from '@angular/core';
import {
  AbstractGraphqlRequest,
  GraphqlRequestType,
  ItemNotFound,
} from '@ckmk/angular';
import { TypedDocumentNode } from '@apollo/client';
import { SolutionCategoryModel } from '../model/solution.category.model';
import { SolutionCategoryQuery } from '../../../queries/category/solution.category.query';

@Injectable({
  providedIn: 'root',
})
export class SolutionCategoryRequestQuery extends AbstractGraphqlRequest<
  SolutionCategoryModel | ItemNotFound
> {
  protected override type: GraphqlRequestType = 'query';

  protected override getQuery(): TypedDocumentNode<any, any> {
    return SolutionCategoryQuery;
  }

  protected override onParseResult(data: any, payload?: any) {
    let item = data.findOneSolutionCategory;

    if (item) {
      return new SolutionCategoryModel(item);
    }

    return new ItemNotFound();
  }
}
