import { Injectable } from '@angular/core';
import { AbstractGraphqlRequest, GraphqlRequestType } from '@ckmk/angular';
import { TypedDocumentNode } from '@apollo/client';
import { SolutionCategoryModel } from '../model/solution.category.model';
import { SolutionCategoryCreateMutation } from '../../../queries/category/solution.category.create.mutation';
import { SolutionCategoryUpdateMutation } from '../../../queries/category/solution.category.update.mutation';

@Injectable({
  providedIn: 'root',
})
export class SolutionCategoryRequestMutation extends AbstractGraphqlRequest<SolutionCategoryModel> {
  protected override type: GraphqlRequestType = 'mutation';

  protected override getQuery(payload: any): TypedDocumentNode<any, any> {
    return payload.data.id
      ? SolutionCategoryUpdateMutation
      : SolutionCategoryCreateMutation;
  }

  protected override onParseResult(data: any, payload?: any) {
    const categoryData =
      data.createSolutionCategory || data.updateSolutionCategory;

    if (categoryData) {
      return new SolutionCategoryModel(categoryData);
    }

    return null;
  }
}
