import { gql } from 'apollo-angular';

export const ServiceCategoryUpdateMutation = gql`
  mutation updateServiceCategory($data: ServiceCategoryUpdateInput!) {
    updateServiceCategory(data: $data) {
      id
      cover {
        id
        name
        originalName
        extension
        active
        title
        description
        directory
        directories
        chunkEnd
        chunkStart
        frontUrls
        isImage
        mimetype
        temp
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
      slug
      title
      description
      shortDescription
      data {
        leftColor
        rightColor
      }
      active
      createdAt
      updatedAt
      deletedAt
      createdBy
      updatedBy
    }
  }
`;
