import { Injectable } from '@angular/core';
import { AbstractGraphqlRequest, GraphqlRequestType } from '@ckmk/angular';
import { TypedDocumentNode } from '@apollo/client';
import { ServiceCategoryModel } from '../model/service.category.model';
import { ServiceCategoryCreateMutation } from '../../../queries/category/service.category.create.mutation';
import { ServiceCategoryUpdateMutation } from '../../../queries/category/service.category.update.mutation';

@Injectable({
  providedIn: 'root',
})
export class ServiceCategoryRequestMutation extends AbstractGraphqlRequest<ServiceCategoryModel> {
  protected override type: GraphqlRequestType = 'mutation';

  protected override getQuery(payload: any): TypedDocumentNode<any, any> {
    return payload.data.id
      ? ServiceCategoryUpdateMutation
      : ServiceCategoryCreateMutation;
  }

  protected override onParseResult(data: any, payload?: any) {
    const categoryData =
      data.createServiceCategory || data.updateServiceCategory;

    if (categoryData) {
      return new ServiceCategoryModel(categoryData);
    }

    return null;
  }
}
