import {
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ServiceModel } from '../../../classes/default/model/service.model';
import {
  BreadcrumbItems,
  ColumnActionEventInterface,
  ComponentsModule,
  DashboardModule,
  DatatableModule,
  PaginationResult,
  TranslationModule,
} from '@ckmk/angular';
import { ServiceFetchAllPayload } from '../../../types/types';
import { ServiceService } from '../../../services/service.service';
import { ServiceDatatableType } from '../../../classes/default/datatable/service.datatable.type';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-service-list',
  imports: [
    DatatableModule,
    DashboardModule,
    TranslationModule,
    ComponentsModule,
  ],
  templateUrl: './service-list.component.html',
  styleUrl: './service-list.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ServiceListComponent implements OnInit, OnChanges, OnDestroy {
  protected services: ServiceModel[] = [];

  protected pagination?: PaginationResult;

  protected loading: boolean = false;

  protected breadcrumbItems: BreadcrumbItems = [
    {
      label: 'Services',
    },
  ];

  protected icons = {
    add: faPlus,
  };

  public constructor(
    private readonly _self: ElementRef,
    private readonly _service: ServiceService,
    protected readonly datatableType: ServiceDatatableType,
  ) {}

  public async ngOnInit() {
    this._service.servicesQuery.onQuerySuccess((response, loading) => {
      this.loading = loading;

      if (!loading && response) {
        const { result: services, pagination } = response;

        if (pagination) {
          this.pagination = pagination;
        }
        this.services = services;
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges) {}

  public ngOnDestroy() {}

  protected onPayload(payload?: ServiceFetchAllPayload): void {
    this._service.fetchAll(payload);
  }

  protected onEvent(event: ColumnActionEventInterface) {
    event.run('delete', () => {
      console.log('Run delete');
    });

    event.run('restore', () => {
      console.log('Run restore');
    });
  }
}
