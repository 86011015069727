import { AbstractModel } from '@ckmk/angular';

export abstract class AbstractData extends AbstractModel {
  /**
   * The category leftColor
   * @protected
   */
  protected _leftColor?: string;
  public get leftColor(): string | undefined {
    return this._leftColor;
  }
  public set leftColor(leftColor: string | undefined) {
    this._leftColor = leftColor;
  }

  /**
   * The category rightColor
   * @protected
   */
  protected _rightColor?: string;
  public get rightColor(): string | undefined {
    return this._rightColor;
  }
  public set rightColor(rightColor: string | undefined) {
    this._rightColor = rightColor;
  }

  public constructor(data?: any) {
    super();

    Object.assign(this, data || {});
  }
}
