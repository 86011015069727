import {
  AdminModel,
  AdminService,
  dashboardPath,
  DatatableBuilderInterface,
  DatatableTypeInterface,
  PaginationRequest,
} from '@ckmk/angular';
import { Injectable } from '@angular/core';
import {
  faCopy,
  faEye,
  faPenToSquare,
  faTrash,
  faTrashArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { SolutionCategoryModel } from '../model/solution.category.model';

@Injectable({
  providedIn: 'root',
})
export class SolutionCategoryDatatableType implements DatatableTypeInterface {
  public constructor(private readonly _adminService: AdminService) {}

  public async configure(builder: DatatableBuilderInterface): Promise<void> {
    const auth = (await this._adminService.fetchAuth()) as AdminModel;

    builder
      .setAuth(auth)
      .add({
        type: 'text',
        name: 'id',
        label: 'Id',
        config: {
          guard: {
            roles: ['ROLE_DEV'],
          },
        },
      })
      .add({
        type: 'translation',
        name: 'title',
        label: 'Title',
        config: {},
      })
      .add({
        type: 'text',
        name: 'slug',
        label: 'Slug',
        config: {},
      })
      .add({
        type: 'datetime',
        name: 'createdAt',
        label: 'Created at',
        config: {
          format: 'datetime',
          timezone: auth.timezone,
          guard: {
            roles: ['ROLE_DEV', 'ROLE_OWNER', 'ROLE_ADMIN'],
          },
        },
      })
      .add({
        type: 'datetime',
        name: 'updatedAt',
        label: 'Updated at',
        config: {
          format: 'datetime',
          timezone: auth.timezone,
          guard: {
            roles: ['ROLE_DEV', 'ROLE_OWNER', 'ROLE_ADMIN'],
          },
        },
      })
      .add({
        type: 'datetime',
        name: 'deletedAt',
        label: 'Deleted at',
        config: {
          format: 'datetime',
          timezone: auth.timezone,
          guard: {
            roles: ['ROLE_DEV'],
          },
        },
      })
      .add({
        type: 'text',
        name: 'createdBy',
        label: 'Created by',
        config: {
          guard: {
            roles: ['ROLE_DEV', 'ROLE_OWNER', 'ROLE_ADMIN'],
          },
        },
      })
      .add({
        type: 'text',
        name: 'updatedBy',
        label: 'Updated by',
        config: {
          guard: {
            roles: ['ROLE_DEV', 'ROLE_OWNER', 'ROLE_ADMIN'],
          },
        },
      })
      .withPayload({
        filters: [],
        pagination: new PaginationRequest(),
        sorts: { createdAt: 'DESC' },
        includeSoftDeleted: true,
      })
      .withActions([
        {
          name: 'show',
          label: 'Show',
          icon: faEye,
          guard: {
            permissions: ['PERMISSION_READ_SOLUTION_CATEGORY'],
          },
          activeColor: 'var(--color-warning)',
          path: (sc: SolutionCategoryModel): any => [
            dashboardPath('/solutions/categories/show'),
            sc.id,
          ],
          filter: (sc: SolutionCategoryModel) => !sc.deletedAt,
        },
        {
          name: 'update',
          label: 'Update',
          icon: faPenToSquare,
          guard: {
            permissions: ['PERMISSION_UPDATE_SOLUTION_CATEGORY'],
          },
          activeColor: 'var(--color-success)',
          path: (sc: SolutionCategoryModel): any => [
            dashboardPath('/solutions/categories/update'),
            sc.id,
          ],
          filter: (sc: SolutionCategoryModel) => !sc.deletedAt,
        },
        {
          name: 'copy',
          label: 'Copy',
          icon: faCopy,
          guard: {
            permissions: ['PERMISSION_COPY_SOLUTION_CATEGORY'],
          },
          activeColor: 'var(--color-primary)',
          path: (sc: SolutionCategoryModel): any => [
            dashboardPath('/solutions/categories/copy'),
            sc.id,
          ],
          filter: (sc: SolutionCategoryModel) => !sc.deletedAt,
        },
        {
          name: 'delete',
          label: 'Delete',
          icon: faTrash,
          guard: {
            permissions: ['PERMISSION_SOFT_DELETE_SOLUTION_CATEGORY'],
          },
          activeColor: 'var(--color-danger)',
          filter: (sc: SolutionCategoryModel) => !sc.deletedAt,
        },
        {
          name: 'restore',
          label: 'Restore',
          icon: faTrashArrowUp,
          guard: {
            roles: ['ROLE_DEV'],
          },
          activeColor: 'var(--color-danger)',
          filter: (sc: SolutionCategoryModel) => !!sc.deletedAt,
        },
      ])
      .saveToStorage();
  }
}
