import {
  FieldTypesConfigList,
  FileAcceptTypesEnum,
  FormBuilderInterface,
  FormTypeInterface,
} from '@ckmk/angular';
import { Injectable } from '@angular/core';
import { SolutionCategoryModel } from '../model/solution.category.model';

@Injectable({
  providedIn: 'root',
})
export class SolutionCategoryFormType implements FormTypeInterface {
  public fields: FieldTypesConfigList = [];

  public constructor() {}

  public async configure(
    builder: FormBuilderInterface<SolutionCategoryModel>,
  ): Promise<void> {
    const data = builder.getData();

    if (data?.id) {
      builder.add({
        type: 'choice',
        name: 'active',
        label: 'Enable',
        required: false,
        help: 'Enable help',
        config: {
          type: 'checkbox-boolean',
          buttonType: 'button-only',
        },
      });
    }

    builder
      .add({
        type: 'file',
        name: 'cover',
        label: 'Cover',
        required: true,
        config: {
          accept: FileAcceptTypesEnum.IMAGES,
        },
      })
      .add({
        type: 'translation',
        name: 'title',
        label: 'Title',
        required: true,
        config: {
          type: 'text',
          key: data?.title?.key,
          domain: data?.title?.domain || 'solutions_categories',
        },
      })
      .add({
        type: 'translation',
        name: 'description',
        label: 'Description',
        required: true,
        config: {
          type: 'longtext',
          key: data?.description?.key,
          domain: data?.description?.domain || 'solutions_categories',
        },
      })
      .add({
        type: 'translation',
        name: 'shortDescription',
        label: 'Short description',
        required: true,
        config: {
          type: 'longtext',
          key: data?.shortDescription?.key,
          domain: data?.shortDescription?.domain || 'solutions_categories',
        },
      })
      .add({
        type: 'group',
        name: 'data',
        required: true,
        config: {
          configure(bd: FormBuilderInterface): void {
            bd.add({
              type: 'color',
              name: 'leftColor',
              label: 'Left color',
              config: {},
            });

            bd.add({
              type: 'color',
              name: 'rightColor',
              label: 'Right color',
              config: {},
            });
          },
        },
      });
  }
}
