import {
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  BreadcrumbItems,
  ColumnActionEventInterface,
  ComponentsModule,
  DashboardModule,
  DatatableModule,
  PaginationResult,
  TranslationModule,
} from '@ckmk/angular';
import { SolutionCategoryDatatableType } from '../../../classes/category/datatable/solution.category.datatable.type';
import { SolutionService } from '../../../services/solution.service';
import { SolutionCategoryFetchAllPayload } from '../../../types/types';
import { SolutionCategoryModel } from '../../../classes/category/model/solution.category.model';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-solution-category-list',
  imports: [
    DatatableModule,
    DashboardModule,
    TranslationModule,
    ComponentsModule,
  ],
  templateUrl: './solution-category-list.component.html',
  styleUrl: './solution-category-list.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SolutionCategoryListComponent
  implements OnInit, OnChanges, OnDestroy
{
  protected categories: SolutionCategoryModel[] = [];

  protected pagination?: PaginationResult;

  protected loading: boolean = false;

  protected breadcrumbItems: BreadcrumbItems = [
    {
      label: 'Solutions',
      path: '/solutions/list',
    },
    {
      label: 'Categories',
    },
  ];

  protected icons = {
    add: faPlus,
  };

  public constructor(
    private readonly _self: ElementRef,
    private readonly _service: SolutionService,
    protected readonly datatableType: SolutionCategoryDatatableType,
  ) {}

  public async ngOnInit() {
    this._service.categoriesQuery.onQuerySuccess((response, loading) => {
      this.loading = loading;

      if (!loading && response) {
        const { result: categories, pagination } = response;

        if (pagination) {
          this.pagination = pagination;
        }
        this.categories = categories;
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges) {}

  public ngOnDestroy() {}

  protected onPayload(payload?: SolutionCategoryFetchAllPayload): void {
    this._service.fetchAllCategories(payload);
  }

  protected onEvent(event: ColumnActionEventInterface) {
    event.run('show', () => {
      console.log('Run show');
    });

    event.run('update', () => {
      console.log('Run update');
    });

    event.run('copy', () => {
      console.log('Run copy');
    });

    event.run('delete', () => {
      console.log('Run delete');
    });

    event.run('restore', () => {
      console.log('Run restore');
    });
  }
}
