import {
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { SolutionModel } from '../../../classes/default/model/solution.model';
import {
  BreadcrumbItems,
  ColumnActionEventInterface,
  ComponentsModule,
  DashboardModule,
  DatatableModule,
  PaginationResult,
  TranslationModule,
} from '@ckmk/angular';
import { SolutionFetchAllPayload } from '../../../types/types';
import { SolutionService } from '../../../services/solution.service';
import { SolutionDatatableType } from '../../../classes/default/datatable/solution.datatable.type';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-solution-list',
  imports: [
    DatatableModule,
    DashboardModule,
    TranslationModule,
    ComponentsModule,
  ],
  templateUrl: './solution-list.component.html',
  styleUrl: './solution-list.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SolutionListComponent implements OnInit, OnChanges, OnDestroy {
  protected solutions: SolutionModel[] = [];

  protected pagination?: PaginationResult;

  protected loading: boolean = false;

  protected breadcrumbItems: BreadcrumbItems = [
    {
      label: 'Solutions',
    },
  ];

  protected icons = {
    add: faPlus,
  };

  public constructor(
    private readonly _self: ElementRef,
    private readonly _service: SolutionService,
    protected readonly datatableType: SolutionDatatableType,
  ) {}

  public async ngOnInit() {
    this._service.solutionsQuery.onQuerySuccess((response, loading) => {
      this.loading = loading;

      if (!loading && response) {
        const { result: solutions, pagination } = response;

        if (pagination) {
          this.pagination = pagination;
        }
        this.solutions = solutions;
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges) {}

  public ngOnDestroy() {}

  protected onPayload(payload?: SolutionFetchAllPayload): void {
    this._service.fetchAll(payload);
  }

  protected onEvent(event: ColumnActionEventInterface) {
    event.run('show', () => {
      console.log('Run show');
    });

    event.run('update', () => {
      console.log('Run update');
    });

    event.run('copy', () => {
      console.log('Run copy');
    });

    event.run('delete', () => {
      console.log('Run delete');
    });

    event.run('restore', () => {
      console.log('Run restore');
    });
  }
}
